import { useContext, useState } from "react";
import { GlobalDataContext } from "../../context/context";
import { FaStar } from "react-icons/fa";
import Starts from "./Customreviews/Stars";


export default function CustomReviews() {

    const { rpdata } = useContext(GlobalDataContext);

    const [reviewsDB] = useState([]);



    const totalReviews = reviewsDB.map(item => (item.ReviewsStars >= 3 ? item.ReviewsStars : 0)).reduce((prev, curr) => prev + curr, 0);
    const porcReviews = (totalReviews / reviewsDB.length).toFixed(2)

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text-[15px] leading-relaxed">
                {isReadMore ? text.substring(0, 60) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };


    return (
        <section className="w-full flex flex-col items-center justify-center py-20 h-auto ">
            <div className="w-full flex flex-col bg-[#f3f3f3] h-auto rounded-xl">
                <div className="px-5 justify-start flex py-5 text-center flex-wrap">
                    <div className="flex items-center border-gray-500 border-b-[4px]">
                        <p className="px-2">All Reviews</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>

                    <div className="flex px-5 items-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fgoogle.png?alt=media&token=1134154d-85f9-4de4-8cd2-cade333b2b98" alt="google_svg" className="w-[25px] h-[25px]" />
                        <p className="px-2">Google</p>
                        <p className="font-semibold">5.0</p>
                    </div>
                </div>

                <div className="flex w-full h-auto px-5 border-t justify-between">
                    <div className="flex flex-col">
                        <div><p className="text-[20px]">Overall Rating</p></div>
                        <div className="flex">
                            <div><p className="font-bold text-[18px]">5.0</p></div>
                            <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2`}>
                                <div className="flex">
                                    <FaStar className="text-yellow-500 text-[20px]" />
                                    <FaStar className={`text-yellow-500 text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-yellow-500 text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-yellow-500 text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-yellow-500 text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-7">
                        <a href={rpdata?.gmb?.link} className="p-3 bg1 rounded-md text-white font-bold" target="_blank" rel='noopener noreferrer'>
                            Write a Review
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-full h-auto my-10 mx-auto gap-5 grid md:grid-cols-5 grid-cols-1">
                {
                    rpdata?.works?.map((item, index) => {
                        return (
                            <article key={index} className="h-auto">

                                <div className="p-5 flex flex-col rounded-[28px] items-start mb-2 bg-[#f3f3f3] w-auto relative">
                                    <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2 `}>
                                        <div className="flex">
                                            <Starts className="text-[20px]" score={item.subdescription}/>
                                        </div>
                                    </div>
                                    <ReadMore>{item.description}</ReadMore>

                                </div>
                                <div className="bg-[#f3f3f3] w-[25px] h-[15px] relative overflow-hidden rounded-br-[30px] -mt-2 ml-7">
                                    <div className="w-[45px] h-[100px] bg-white rounded-full absolute -left-8 -bottom-1">
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col">
                                        <div className="py-4 flex space-x-2">
                                            <div className="flex justify-center">
                                                <img src={item.gallery}
                                                    alt="perfil-foto"
                                                    className="w-[40px] h-[40px] rounded-full object-cover" />
                                            </div>

                                            <div>
                                                <div>
                                                    <span className="text-[15px] font-semibold"> {item.name} </span>
                                                </div>
                                                <div className="flex items-end text-[11px] text-gray-400">
                                                    <span>{item?.Date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
        </section>
    );
}
